export function duplicateFileName(fileName: string): string {
  const regex = /(.*)\s\((\d+)\)$/;
  const match = fileName.match(regex);

  if (match) {
    const baseName = match[1];
    const number = parseInt(match[2], 10);
    return `${baseName} (${number + 1})`;
  } else {
    return `${fileName} (1)`;
  }
}
