import type { DependencyContainer } from 'tsyringe';
import type { DpProvider } from '@/core/di/DpProvider';
import { HttpMessageTemplateRepository } from '@/modules/settings/adapters/message-templates/HttpMessageTemplateRepository';
import { MessageTemplateRepositoryToken } from '@/modules/settings/di/token';

export class MessageTemplatesProvider implements DpProvider {
  register(container: DependencyContainer): void {
    container.register(MessageTemplateRepositoryToken, HttpMessageTemplateRepository);
  }
}
