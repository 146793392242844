import { injectable } from 'tsyringe';
import type { MessageTemplateRepository } from '@/modules/settings/domain/message-templates/MessageTemplateRepository';
import { HttpClientApi } from '@/modules/http-client/public/api';
import { MessageTemplate } from '@/modules/settings/domain/message-templates/MessageTemplate';
import { duplicateFileName } from '@/utils/duplicateFileName';
import { mockedTemplates } from '@/__mocks__/MessageTemplates';
import { sleep } from '@/utils/promises';

@injectable()
export class HttpMessageTemplateRepository implements MessageTemplateRepository {
  constructor(private readonly httpClient: HttpClientApi) {}

  // async get(): Promise<MessageTemplate[]> {
  //   const templates: MessageTemplate[] = await this.httpClient.get(
  //     `/api/v0/facility/{facilityId}/message-templates`,
  //   );

  //   if (!templates) {
  //     return [];
  //   }

  //   // TODO improve it when we start receive status from the backend
  //   return templates.map(
  //     ({ id, name, body, status }) => new MessageTemplate(id, name, body, status),
  //   );
  // }

  /**
   * Mocked implementation of the get method
   * @returns Promise<MessageTemplate[]> a list of message templates
   */
  async get(): Promise<MessageTemplate[]> {
    await sleep(1000);
    return mockedTemplates;
  }

  /**
   * Mocked implementation of the create method
   * @param template MessageTemplate the template to create
   * @returns Promise<MessageTemplate> the newly created template
   */
  async saveDraft(template: MessageTemplate): Promise<MessageTemplate> {
    const { id, name, body } = template;
    const templatePayload = new MessageTemplate(id, name, body, 'draft');

    // if (id) {
    //   return await this.httpClient.patch<MessageTemplate>(
    //     '/api/v0/facility/{facilityId}/message-templates',
    //     templatePayload,
    //   );
    // } else {
    //   return await this.httpClient.post<MessageTemplate>(
    //     'api/v0/facility/{facilityId}/message-templates',
    //     templatePayload,
    //   );
    // }

    await sleep(1000);
    console.log('Draft template saved:');
    console.log(templatePayload);
    return templatePayload;
  }

  async submitTemplate(template: MessageTemplate): Promise<MessageTemplate> {
    const { id, name, body } = template;

    const templateToSubmit = id
      ? new MessageTemplate(id, name, body, 'in-review')
      : this.saveDraft(new MessageTemplate(id, name, body, 'in-review')).then(
          newDraft => new MessageTemplate(newDraft.id, newDraft.name, newDraft.body, 'in-review'),
        );

    // await this.httpClient.patch<MessageTemplate>(
    //   '/api/v0/facility/{facilityId}/message-templates',
    //   await templateToSubmit,
    // );

    await sleep(1000);
    console.log('Template submitted:');
    console.log(templateToSubmit);
    return templateToSubmit;
  }

  /**
   * Mocked implementation of the duplicate method
   * @param template MessageTemplate the template to duplicate
   * @returns Promise<MessageTemplate> the newly duplicated template
   */
  async duplicate(template: MessageTemplate): Promise<MessageTemplate> {
    const duplicatedData = new MessageTemplate(
      template.id,
      duplicateFileName(template.name),
      template.body,
      'draft',
    );

    // return await this.create(duplicatedData);

    await sleep(1000);
    console.log('Template duplicated:');
    console.log(duplicatedData);
    return duplicatedData;
  }

  /**
   * Mocked implementation of the duplicate method
   * @param template MessageTemplate the template to duplicate
   * @returns Promise<MessageTemplate> the newly duplicated template
   */
  async delete(templateId: string): Promise<void> {
    // await httpClient.delete(`/api/v0/facility/{facilityId}/message-templates/${templateId}`);
    await sleep(1000);
    console.log('Deleting template with id:');
    console.log(templateId);
  }
}
