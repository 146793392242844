import type { MessageTemplateStatus } from './MessageTemplateStatus';

export class MessageTemplate {
  constructor(
    public id: string | null,
    public name: string,
    public body: string,
    public status: MessageTemplateStatus = 'draft',
  ) {}

  get isNewTemplate(): boolean {
    return this.id === null;
  }

  get isDraft(): boolean {
    return this.status === 'draft';
  }

  get isEditable(): boolean {
    return this.isDraft || this.isNewTemplate;
  }

  get isRejected(): boolean {
    return this.status === 'rejected';
  }

  get isActive(): boolean {
    return this.status === 'active';
  }

  get isInReview(): boolean {
    return this.status === 'in-review';
  }

  static empty(): MessageTemplate {
    return new MessageTemplate(null, '', '');
  }
}
