import { MessageTemplate } from '@/modules/settings/domain/message-templates/MessageTemplate';

export const mockedTemplates: MessageTemplate[] = [
  new MessageTemplate(
    '1',
    'Active template',
    'Adipisicing ullamco exercitation ad officia anim ipsum officia duis in.',
    'active',
  ),
  new MessageTemplate(
    '2',
    'Draft template',
    'Minim occaecat ea sint eu veniam non reprehenderit aliquip ullamco officia ea amet non ex.',
    'draft',
  ),
  new MessageTemplate(
    '3',
    'Rejected template',
    'Dolor ullamco mollit nostrud deserunt est adipisicing eu enim id adipisicing ut.',
    'rejected',
  ),
  new MessageTemplate(
    '4',
    'Rejected template',
    'Dolor ullamco mollit nostrud deserunt est adipisicing eu enim id adipisicing ut.',
    'in-review',
  ),
];
